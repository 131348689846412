import { Injectable } from "@angular/core";
// import 'rxjs/add/operator/toPromise';
import { AngularFirestore } from "@angular/fire/firestore";

import { AngularFireAuth } from "@angular/fire/auth";
import  firebase from "firebase/app";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
// import { environment } from "../../environments/environment"
import { Observable} from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { CookieService } from "ngx-cookie-service";
import { environment } from 'src/environments/environment';
// import { fromEvent, Subject } from 'rxjs';
//  import { takeUntil } from 'rxjs/operators';
// import { LocationStrategy } from "@angular/common";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//
@Injectable()
export class UserService {
  // protected unsubscriber: Subject<void> = new Subject<void>();
  constructor(public db: AngularFirestore,
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    private cookieService: CookieService) { }
    sTokenValue:any
  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }



  sendEmailVerification() {
    var user = firebase.auth().currentUser;
    user.sendEmailVerification().then(function () {
      // Email sent.
    }).catch(function (error) {
      // An error happened.
    });
  }

  updateCurrentUser(value) {
    return new Promise<any>((resolve, reject) => {
      var user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: value.name,
          photoURL: user.photoURL
        })
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  getAccessUserDetails(id: string, token: any) {
    var strServiceURL = environment.service_url + "cicp/accessUser/" + id;

    // console.log("Service URL: " + strServiceURL);
    return this.http.get<any>(strServiceURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
    //return this.http.get<any>("https://microservices.qpathways.com/auth/cicp/authorizeUser/" + id);
  }

  sendVerificationEmail(username: string, queryParam?: string) {
    var strServiceURL = environment.service_url + "cicp/sendVerificationEmail?appname=QAdmin&username=" + username;
    if (queryParam) {
      strServiceURL = `${strServiceURL}&&${queryParam}`
    }
    return this.http.get<any>(strServiceURL, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.sTokenValue) });

  }

  isEmailActiveForPasswordRecovery(username: string) {
    return this.http.get<any>(environment.service_url + "users/checkUserActive?username=" + username);
  }

  updateUserAccess(userid: string, token: any) {
    const obj = {'userid':userid}
    return this.http.post<any>(environment.service_url + "users/updateuserlastaccess", obj, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  encryptData(data: any): string {

    var message = JSON.stringify(data);
    return CryptoJS.AES.encrypt((message), environment.algokey).toString();
  }

  decryptData(encryptedData: any): any {
    var decrypted = CryptoJS.AES.decrypt(encryptedData, environment.algokey);
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
  }

  public setLocalStorage(key: any, value: any) {
    if (value === "null") {
      return localStorage.setItem(key, (value))
    } else if (value === "") {
      return localStorage.setItem(key, (value))
    } else if (value === "[]") {
      return localStorage.setItem(key, (value))
    } else {
      return localStorage.setItem(key, this.encryptData(value))
    }
  }

  public getLocalStorage(key: any) {

    if (localStorage.getItem(key)) {
      var value = localStorage.getItem(key)
      if (value === "null") {
        return value
      } else if (value === "") {
        return value
      } else if (value === "[]") {
        return value
      } else {
        return this.decryptData(localStorage.getItem(key));
      }
    }
    else{
    return ""
    }
  }

  public setSessionStorage(key: any, value: any) {

    if (value === "null") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "[]") {
      return sessionStorage.setItem(key, (value))
    } else {
      return sessionStorage.setItem(key, this.encryptData(value))
    }
  }

  public getSessionStorage(key: any) {
    if (sessionStorage.getItem(key)) {
      var value = sessionStorage.getItem(key)
      if (value === "null") {
        return value
      } else if (value === "") {
        return value
      } else if (value === "[]") {
        return value
      } else {
            return this.decryptData(sessionStorage.getItem(key));
      }
    }
    else{
    return ""
    }
  }

  public removeLocalStorage(key: any) {
    return localStorage.removeItem(key);
  }
  public removeSessionStorage(key: any) {
      return sessionStorage.removeItem(key);
  }

  public setCookies(key: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: "Lax" | "None" | "Strict") {
   if(value === "" || value === 'null' || value === "[]"){
    return this.cookieService.set(key,value), expires ? expires : 0 , path ? path : '', domain? domain : '', secure? secure: null, sameSite? sameSite: null;
   }
   else{
    return this.cookieService.set(key, this.encryptData(value), expires ? expires : 0 , path ? path : '', domain? domain : '', secure? secure: null, sameSite? sameSite: null);
   }
  }

  public getCookies(key: string) {
    var cookieValue = this.cookieService.get(key);
    if (cookieValue === "null") {
      return cookieValue
    } else if (cookieValue === "") {
      return cookieValue
    } else if (cookieValue === "[]") {
      return cookieValue
    }
    else{
      return this.decryptData(this.cookieService.get(key));
    }
  }

  // getBackArrowDisableOfBrowser(){
    // if(location.href){
    //     history.pushState(null, "", location.href);
    //     this.location.onPopState(() => {
    //     history.pushState(null, "", location.href);
    // });
    // }

    //   history.pushState(null, '');
    //   fromEvent(window, 'popstate')
    //     .pipe(takeUntil(this.unsubscriber))
    //     .subscribe((_) => {
    //       history.pushState(null, '');
    //     });
    // }

    // ngOnDestroy(): void {
    //   this.unsubscriber.next();
    //   this.unsubscriber.complete();
    // }
}
